<template>
  <div class="about">
    <h1>User List</h1>
    <v-container>
      <v-data-table :headers="headers" :items="users" :search="search">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            class="pa-2"
            label="Search"
          ></v-text-field>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn class="mr-2" :to="`/users/${item.id}`">Detail</v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      users: [],
      search: '',
      headers: [
        { title: 'Name', value: 'name', sortable: true },
        { title: 'Email', value: 'email', sortable: true },
        { title: 'Company Name', value: 'companyName', sortable: true },
        { title: 'Phone Number', value: 'phoneNumber', sortable: true },
        { title: 'Action', value: 'action', sortable: false },
      ],
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get('https://api.cetaklabel.id/user/', {
          headers: {
            Authorization: `token ${this.$store.state.token}`,
          },
        });
        this.users = response.data.data; 
        console.log('Users fetched:', this.users);
      } catch (error) {
        console.error('Error fetching users:', error);
        alert("Error!");
      }
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
.pa-2 {
  padding: 16px;
}
</style>
