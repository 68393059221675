<template>
    <div>
        <div class="about">
            <h1>{{ user.name }}</h1>
            <p class="info-row">
                <span>Company Name: </span>
                <span class="standerd-bold">{{ user.companyName }}</span>
            </p>

            <p class="info-row">
                <span>Email: </span>
                <span class="standerd-bold">{{ user.email }}</span>
            </p>

            <p class="info-row mb-4">
                <span>Phone Number: </span>
                <span class="standerd-bold">{{ user.phoneNumber }}</span>
            </p>

            <v-container>
                <v-data-table :headers="headers" :items="checkoutList" :search="search">
                    <template v-slot:top>
                        <v-text-field v-model="search" class="pa-2" label="Search"></v-text-field>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip :color="getBadgeColor(item.status)" small>
                            {{ translateStatus(item.status) }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.userId`]="{ item }">
                        <v-btn class="mr-2" :to="`/user/${item.userId}`">User</v-btn>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn class="mr-2" :to="`/orders/${item.id}`">Detail</v-btn>
                    </template>
                </v-data-table>
            </v-container>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
            checkoutList: [],
            user: {},
            headers: [
                { title: 'Created', value: 'created', sortable: true },
                { title: 'Last Updated', value: 'lastUpdated', sortable: true },
                { title: 'No Referensi', value: 'refNumber', sortable: true },
                { title: 'Status', value: 'status', sortable: true },
                { title: 'Checkout Items', value: 'numOfItems', sortable: true },
                { title: 'Action', value: 'action', sortable: false },
            ],
            search: ""
        };
    },
    created() {
        this.fetchUser()
        this.fetchCheckouts()
    },
    methods: {
        async fetchUser() {
            try {
                const response = await axios.get('https://api.cetaklabel.id/user/' + `${this.$route.params.id}`, {
                    headers: {
                        Authorization: `token ${this.$store.state.token}`,
                    },
                });
                this.user = response.data.data;
                console.log('Users fetched:', this.users);
            } catch (error) {
                console.error('Error fetching user:', error);
                alert("Error!");
            }
        },
        async fetchCheckouts() {
            try {
                const response = await axios.get(`${this.$store.state.baseUrl}/checkout/${this.$route.params.id}`, {
                    headers: {
                        Authorization: `token ${this.$store.state.token}`,
                    },
                });
                this.checkoutList = response.data.data.map(
                    (it) => ({
                        ...it,
                        numOfItems: it.checkoutItems.length,
                        created: this.formatDate(it.created),
                        lastUpdated: this.formatDate(it.lastUpdated),
                    })
                ).sort((a, b) => {
                    return new Date(b.created) - new Date(a.created);
                });
                this.$store.dispatch('setCheckoutList', this.checkoutList)
            } catch (error) {
                console.error('Error fetching checkout:', error);
                alert("Error!");
            }
        },
        getBadgeColor(status) {
            const colorMap = {
                'WAITING_PAYMENT_PROOF': 'blue',
                'WAITING_VERIFICATION': 'red',
                'PAYMENT_PROOF_REJECTED': 'grey',
                'PAYMENT_PROOF_APPROVED': 'grey',
                'COMPLETED': 'grey'
            };
            return colorMap[status] || 'grey'; // Default color if status is not found
        },
        translateStatus(status) {
            const statusMap = {
                'WAITING_PAYMENT_PROOF': 'Menunggu Pembayaran',
                'WAITING_VERIFICATION': 'Pembayaran Sedang Diverifikasi',
                'PAYMENT_PROOF_REJECTED': 'Pesanan Dibatalkan',
                'PAYMENT_PROOF_APPROVED': 'Pesanan Diproses',
                'COMPLETED': 'Pesanan Dikirim'
            };
            return statusMap[status] || status; // Return original status if not found in map
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        },
    },
}
</script>

<style>
.info-row .v-col {
    padding-top: 0;
    /* Reduces space within the columns if needed */
    padding-bottom: 0;
}

.text-end {
    text-align: right;
    /* Ensures labels are right-aligned */
}
</style>
