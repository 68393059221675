<template>
  <div class="about">
    <h1>Order {{ item.refNumber }}</h1>
    <v-container>
      <v-card>
        <v-container class="d-flex flex-column text-left">
          <v-row>
            <v-col cols="2">
              <p><b>Customer</b></p>
            </v-col>
            <v-col>
              <p><u>{{ user.name }}</u></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Order Time</b></p>
            </v-col>
            <v-col>
              <p>{{ item.created }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Status</b></p>
            </v-col>
            <v-col>
              <v-row>
                <p><b>{{ item.status }}</b></p>
              </v-row>
              <v-row>
                <v-col cols="2">Update Status</v-col>
                <v-col cols="3"><v-select label="Status" density="compact" :items="[
                  'PAYMENT_PROOF_REJECTED',
                  'PAYMENT_PROOF_APPROVED',
                  'COMPLETED',
                ]" v-model="targetStatus"></v-select></v-col>
                <v-col>
                  <v-btn @click="updateCheckoutStatus"> Update </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Order Items</b></p>
            </v-col>
            <v-col>
              <!-- Product 1 -->
              <v-container v-for="(order, index) in item.checkoutItems" :key="index">
                <v-row>
                  <v-col cols="1"><b>{{ index + 1 }}</b></v-col>
                  <v-col>
                    <div>
                      <v-row>
                        <u>{{ order.order.productName }}</u>
                      </v-row>
                      <v-row>
                        <v-col cols="2"><b>Qty</b></v-col>
                        <v-col>{{ order.order.qty }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2"><b>Total Harga Produk</b></v-col>
                        <v-col>{{ formatRupiah(order.order.price) }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2"><b>Biaya Pengiriman</b></v-col>
                        <v-col>{{ formatRupiah(order.order.deliveryFee) }}</v-col>
                      </v-row>
                      <v-row v-for="(spec, specIndex) in order.order.specifications" :key="specIndex">
                        <v-col cols="2"><b>{{ spec.specificationName }}</b></v-col>
                        <v-col>{{ spec.specificationOptionName }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2"><b>Catatan</b></v-col>
                        <v-col>{{ getNotes(order.order.notes) }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2"><b>Alamat Pengiriman</b></v-col>
                        <v-col>{{ getAddress(order.order.notes) }}</v-col>
                      </v-row>
                      <!-- <v-row>
                        <v-col cols="2"><b>Desain</b></v-col>
                        <v-col>
                          <div class="d-flex">
                            <v-img v-for="(img, imgIndex) in order.imageUploads" :key="imgIndex" class="mx-auto"
                              height="100" :lazy-src="img" max-width="200" src="https://bad.src/not/valid">
                              <template v-slot:placeholder>
                                <div class="d-flex align-center justify-center fill-height">
                                  <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
                                </div>
                              </template>
                            </v-img>
                          </div>
                        </v-col>
                      </v-row> -->
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Payment</b></p>
            </v-col>
            <v-col>
              <div>
                <!-- <v-row>
                  <v-col cols="2"> Uploaded On</v-col>
                  <v-col>2024-02-01 18.00 PM</v-col>
                </v-row> -->
                <v-row v-if="item.proofOfPayment != null">
                  <v-img class="mx-auto" height="100" :lazy-src="getImageUrl(item.proofOfPayment)" max-width="200"
                    :src="getImageUrl(item.proofOfPayment)">
                    <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
                      </div>
                    </template>
                  </v-img>
                </v-row>
                <v-row v-if="item.proofOfPayment == null">
                  Customer belum melakukan pembayaran
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Total</b></p>
            </v-col>
            <v-col>
              <p>{{ formatRupiah(getTotalCheckoutPrice) }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      checkoutId: '',
      item: {},
      user: {},
      total: 0,
      targetStatus: ''
    }
  },
  computed: {
    getTotalCheckoutPrice() {
      if (this.item.checkoutItems == undefined || this.item.checkoutItems == null) {
        return 0
      } else {
        return this.item.checkoutItems.reduce((total, it) => {
        return total + Number(it.order.price) + Number(it.order.deliveryFee);
      }, 0);
      }
    }
  },
  methods: {
    getImageUrl(path){
      return "https://firebasestorage.googleapis.com/v0/b/cetaklabel2024-ad87c.appspot.com/o/" + path + "?alt=media"
    },
    formatRupiah(num) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0
      }).format(num)
    },
    getNotes(str){
      const strSplit = str.split("#####")
      return strSplit[1] ? strSplit[1] : "-"
    },
    getAddress(str){
      const strSplit = str.split("#####")
      return strSplit[0] ? strSplit[0] : "-"
    },
    async getCheckoutDetail() {
      try {
        const response = await axios.get('https://api.cetaklabel.id/checkout/detail/' + `${this.$route.params.id}`, {
          headers: {
            Authorization: `token ${this.$store.state.token}`,
          },
        });
        if(response.data.statusCode == 200) {
          this.item = response.data.data
        } else {
          alert("Error!");
        }
        this.fetchUser();
      } catch (error) {
        console.error('Error fetching checkout:', error);
        alert(error.response.data.message);
      }
    },
    async fetchUser() {
      try {
        const response = await axios.get('https://api.cetaklabel.id/user/' + `${this.item.userId}`, {
          headers: {
            Authorization: `token ${this.$store.state.token}`,
          },
        });
        this.user = response.data.data;
        console.log('Users fetched:', this.users);
      } catch (error) {
        console.error('Error fetching user:', error);
        alert("Error!");
      }
    },
    async updateCheckoutStatus() {
      if (this.targetStatus === "") {
        alert("Mohon isi status tujuan")
      } else {
        const updateStatusBody = {
          "refNumber": this.item.refNumber,
          "status": this.targetStatus
        }
        try {
          const response = await axios.post('https://api.cetaklabel.id/checkout/update-status',
            updateStatusBody,
            {
              headers: {
                Authorization: `token ${this.$store.state.token}`,
              },
            });
          if (response.data.statusCode != 201) {
            alert("Status changed!")
          } else {
            alert("Error!")
          }
        } catch (error) {
          alert("Error!");
        }
      }
    },
  },
  created() {
    this.getCheckoutDetail()
  }
}
</script>

<style></style>
