import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'; 
import OrderView from '../views/OrderView.vue'
import CategoryView from '../views/CategoryView.vue'
import LoginView from '../views/LoginView.vue'
import ProductView from '../views/ProductView.vue'
import UserView from '../views/UserView.vue'
import BlogView from '../views/BlogView.vue'
import OrderDetailView from '../views/OrderDetailView.vue'
import ProductDetailView from '../views/ProductDetailView.vue'
import BlogDetailView from '../views/BlogDetailView.vue'
import UserDetailView from '../views/UserDetailView.vue'

const routes = [
  {
    path: '/',
    name: 'orders',
    component: OrderView
  },
  {
    path: '/category',
    name: 'category',
    component: CategoryView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/orders/:id',
    name: 'orderdetail',
    component: OrderDetailView,
  },
  {
    path: '/products',
    name: 'products',
    component: ProductView
  },
  {
    path: '/products/:id',
    name: 'productdetail',
    component: ProductDetailView
  },
  {
    path: '/users',
    name: 'users',
    component: UserView
  },
  {
    path: '/users/:id',
    name: 'userdetail',
    component: UserDetailView
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: BlogView
  },
  {
    path: '/blogs/:id',
    name: 'blogdetail',
    component: BlogDetailView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.state.token) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router
