import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify'; // Adjust path as needed
import { loadFonts } from './plugins/webfontloader'; // Assuming this is a custom plugin for font loading
import router from './router'; // Assuming this is your Vue Router configuration
import store from './store'; // Assuming this is your Vuex store configuration

loadFonts();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');
