<template>
  <div class="about">
    <h1 v-if="this.productId == 0">Buat Produk Baru</h1>
    <h1 v-if="this.productId != 0">{{ productModel.name }}</h1>
    <v-container>
      <v-card>
        <v-container class="d-flex flex-column text-left">
          <v-row>
            <v-col cols="2">
              <p><b>Title</b></p>
            </v-col>
            <v-col>
              <v-text-field density="compact" hide-details required v-model="productModel.name"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Description</b></p>
            </v-col>
            <v-col>
              <v-textarea density="compact" hide-details required v-model="productModel.description"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Category</b></p>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="8">
                  <v-select
:items="categories" label="Category" outlined v-model="productModel.categoryId"
            item-title="name" item-value="id" :placheholder="categoryName"
    ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Images</b></p>
            </v-col>
            <v-col>
              <v-container class="d-flex flex-column">
                <v-row>
                  <v-container v-for="(image, index) in productModel.images" :key="index" class="d-flex">
                    <v-text-field v-model="productModel.images[index]" density="compact" hide-details required
                      class="mr-4" style="display: none;"></v-text-field>
                    <v-img class="mx-auto" :lazy-src="getImageUrl(productModel.images[index])" max-width="20vh"
                      :src="getImageUrl(productModel.images[index])" :key="getRefreshImage">
                      <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                          <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                    <v-btn @click="deleteImage(index)">Delete</v-btn>
                  </v-container>
                </v-row>
                <v-row>
                  <br />
                  <!-- upload start  -->
                  <v-container class="d-flex flex-column">
                    <p><b>Upload New Image</b></p>
                    <div class="d-flex">
                      <!-- <v-text-field density="compact" hide-details required class="mr-4"
                        v-model="imageToBeAdded"></v-text-field> -->
                        <v-file-input label="File input" @change="onFileSelected"></v-file-input>
                    </div>
                  </v-container>
                  <!-- upload end -->
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <!-- PRICE SPEC -->
          <hr />
          <br />
          <v-row>
            <v-col cols="2">
              <p><b>Price Spec</b></p>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="2"><b>Spec Name</b></v-col>
                <v-col>
                  <v-text-field density="compact" hide-details required
                    v-model="productModel.specifications[0].name"></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="productModel.specifications[0].options.length > 0">
                <v-col cols="2"><b>Options</b></v-col>
                <v-col>
                  <v-row class="d-flex flex-wrap">
                    <v-col cols="3 d-flex flex-wrap"
                      v-for="(pricingOption, index) in productModel.specifications[0].options" :key="index">
                      <v-text-field label="Option Name" density="compact" hide-details required
                        v-model="productModel.specifications[0].options[index].name"></v-text-field>
                      <v-text-field label="Option Price" hide-details single-line type="number" density="compact"
                        v-model="productModel.specifications[0].options[index].price" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2"><b>Add New Option</b></v-col>
                <v-col>
                  <v-row class="d-flex flex-wrap">
                    <v-col cols="3 d-flex flex-wrap">
                      <v-text-field label="Option Name" density="compact" hide-details required
                        v-model="pricingOptionToBeAdded.name"></v-text-field>
                      <v-text-field label="Option Price" hide-details single-line type="number" density="compact"
                        v-model="pricingOptionToBeAdded.price" />
                    </v-col>
                    <v-col><v-btn @click="addNewPricingOption()">Add</v-btn></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- DELIVERY PRICE SPEC -->
          <br>
          <hr />
          <br />
          <v-row>
            <v-col cols="2">
              <p><b>Delivery Price Spec</b></p>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="2"><b>Spec Name</b></v-col>
                <v-col>
                  <v-text-field density="compact" hide-details required v-model="productModel.specifications[1].name"
                    disabled></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="productModel.specifications[1].options.length > 0">
                <v-col cols="2"><b>Options</b></v-col>
                <v-col>
                  <v-row class="d-flex flex-wrap">
                    <v-col cols="3 d-flex flex-wrap"
                      v-for="(pricingOption, index) in productModel.specifications[1].options" :key="index">
                      <v-text-field label="Option Name" density="compact" hide-details required
                        v-model="productModel.specifications[1].options[index].name"></v-text-field>
                      <v-text-field label="Option Price" hide-details single-line type="number" density="compact"
                        v-model="productModel.specifications[1].options[index].price" />
                      <v-text-field label="Qty Min" hide-details single-line type="number" density="compact"
                        v-model="productModel.specifications[1].options[index].fromQuantity" />
                      <v-text-field label="Qty Max" hide-details single-line type="number" density="compact"
                        v-model="productModel.specifications[1].options[index].toQuantity" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2"><b>Add New Option</b></v-col>
                <v-col>
                  <v-row class="d-flex flex-wrap">
                    <v-col cols="3 d-flex flex-wrap">
                      <v-text-field label="Option Name" density="compact" hide-details required
                        v-model="deliveryOptionToBeAdded.name"></v-text-field>
                      <v-text-field label="Option Price" hide-details single-line type="number" density="compact"
                        v-model="deliveryOptionToBeAdded.price" />
                      <v-text-field label="Qty Min" hide-details single-line type="number" density="compact"
                        v-model="deliveryOptionToBeAdded.fromQuantity" />
                      <v-text-field label="Qty Max" hide-details single-line type="number" density="compact"
                        v-model="deliveryOptionToBeAdded.toQuantity" />
                    </v-col>
                    <v-col><v-btn @click="addNewDeliveryOption()">Add</v-btn></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- OTHER SPEC -->
          <br>
          <hr />
          <br />
          <v-container v-if="productModel.specifications.length > 2">
            <v-row v-for="(spec, index) in productModel.specifications.slice(2)" :key="index">
              <v-col cols="2">
                <p>
                  [1] Spec <br /><i><b>{{ spec.name }}</b></i>
                </p>
                <v-btn @click="deleteSpec(spec.name)">Delete</v-btn>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="2"><b>Spec Name</b></v-col>
                  <v-col>
                    <v-text-field density="compact" hide-details required v-model="spec.name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2"><b>Options</b></v-col>
                  <v-col>
                    <div class="d-flex flex-wrap">
                      <div class="spec-option d-flex" v-for="(ops, index) in spec.options" :key="index">
                        <p>{{ ops.name }}</p>
                        <!-- <p class="ml-8 text-red">x</p> -->
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <br />
          <hr class="dotted" v-if="productModel.specifications.length > 2">
          <br />
          <v-row>
            <v-container>
              <h3><b>Tambah Spec Lainnya</b></h3>
              <br />
              <v-row>
                <v-col cols="2"><b>Spec Name</b></v-col>
                <v-col>
                  <v-text-field density="compact" hide-details v-model="otherSpecToBeAdded.name"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2"><b>Options</b></v-col>
                <v-col>
                  <div class="d-flex flex-column">
                    <p><b>Tambah Opsi</b></p>
                    <div class="d-flex">
                      <v-text-field density="compact" hide-details v-model="tempOptions" class="mr-4"></v-text-field>
                      <v-btn @click="addNewOtherSpec()">Tambah Spec</v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <br />
          <br>
          <br>
          <v-btn @click="submitProduct()">Save Product</v-btn>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProductDetailView',
  data() {
    return {
      categories: [],
      productModel: {
        name: "",
        images: [],
        isFeatured: false,
        description: "",
        specifications: [
          {
            type: "PRICE",
            name: "",
            options: []
          },
          {
            type: "DELIVERY_FEE",
            name: "Biaya Pengiriman",
            options: []
          }
        ],
        categoryName: "",
        categoryId: ""
      },
      productId: this.$route.params.id === "create" ? 0 : this.$route.params.id,
      imageToBeAdded: "",
      pricingOptionToBeAdded: {
        name: "",
        price: "",
      },
      deliveryOptionToBeAdded: {
        name: "",
        price: "",
        fromQuantity: "",
        toQuantity: "",
      },
      otherSpecToBeAdded: {
        name: "",
        options: [],
      },
      tempOptions: "",
      selectedFile: null,
      refreshImage: 0,
    };
  },
  computed: {
    getRefreshImage() {
      return this.refreshImage
    }
  },
  methods: {
    sortSpecifications(data) {
      const sortedData = []
      const priceData = data.find((it) => it.type == "PRICE")
      const deliveryData = data.find((it) => it.type == "DELIVERY_FEE")
      const otherData = data.filter((it) => it.type != "PRICE").filter((it) => it.type != "DELIVERY_FEE")
      sortedData.push(priceData)
      sortedData.push(deliveryData)
      sortedData.push(...otherData)
      return sortedData;
    },
    getImageUrl(path) {
      if (path.includes("http")) {
        return path
      }
      return "https://firebasestorage.googleapis.com/v0/b/cetaklabel2024-ad87c.appspot.com/o/" + path + "?alt=media"
    },
    async submitProduct() {

      if (this.productModel.name == "") {
        alert("Title cannot be empty")
      } else if (this.productModel.description == "") {
        alert("Description cannot be empty")
      } else if (this.productModel.images.length == 0) {
        alert("Images cannot be empty")
      } else if (this.productModel.specifications[0].name == '' || this.productModel.specifications[0].options.length == 0) {
        alert("Incomplete price specification")
      } else if (this.productModel.specifications[1].name == '' || this.productModel.specifications[1].options.length == 0) {
        alert("Incomplete price delivery specification")
      } else {
        if (this.$route.params.id === "create") {
          await this.createProduct()
        } else {
          await this.updateProduct()
        }

      }
    },
    deleteImage(index) {
      this.productModel.images.splice(index, 1);
    },
    deleteSpec(specName) {
      this.productModel.specifications = this.productModel.specifications.filter((it) => it.name != specName)
      console.log(this.productModel)
    },
    addImage() {
      if (this.imageToBeAdded != '') {
        this.productModel.images.push(this.imageToBeAdded)
        this.imageToBeAdded = ''
      } else {
        alert("Cannot add empty image")
      }
    },
    addNewPricingOption() {
      if (this.pricingOptionToBeAdded.name == '' || this.pricingOptionToBeAdded.price == '') {
        alert("Cannot add new pricing option")
      } else {
        let newOption = {
          name: this.pricingOptionToBeAdded.name,
          price: Number(this.pricingOptionToBeAdded.price),
          type: "PRICE"
        }
        this.productModel.specifications[0].options.push(newOption)
        this.pricingOptionToBeAdded.name = ''
        this.pricingOptionToBeAdded.price = ''
      }
    },
    addNewDeliveryOption() {
      if (this.deliveryOptionToBeAdded.name == '' || this.deliveryOptionToBeAdded.price == '' || this.deliveryOptionToBeAdded.fromQuantity == '' || this.deliveryOptionToBeAdded.toQuantity == '') {
        alert("Cannot add new delivery option")
      } else {
        let newOption = {
          name: this.deliveryOptionToBeAdded.name,
          price: Number(this.deliveryOptionToBeAdded.price),
          fromQuantity: Number(this.deliveryOptionToBeAdded.fromQuantity),
          toQuantity: Number(this.deliveryOptionToBeAdded.toQuantity),
          type: "DELIVERY_FEE"
        }
        this.productModel.specifications[1].options.push(newOption)
        this.deliveryOptionToBeAdded.name = ''
        this.deliveryOptionToBeAdded.price = ''
        this.deliveryOptionToBeAdded.fromQuantity = ''
        this.deliveryOptionToBeAdded.toQuantity = ''
      }
    },
    addNewOtherSpec() {
      let otherSpecOptions = this.tempOptions.split(',');
      if (otherSpecOptions.length <= 0) {
        alert("Options cannot be empty")
      } else if (this.otherSpecToBeAdded.name == '') {
        alert("Name cannot be empty")
      } else {
        let newOption = {
          name: this.otherSpecToBeAdded.name,
          type: "REQUEST",
          options: otherSpecOptions.map((it) => ({
            "name": it,
            "type": "REQUEST"
          }))
        }
        this.productModel.specifications.push(newOption)
        this.otherSpecToBeAdded.name = ''
        this.otherSpecToBeAdded.options = []
        this.tempOptions = ''
      }
    },
    async updateProduct() {
      const url = `${this.$store.state.baseUrl}/product/${this.productId}`; // Get baseUrl from Vuex
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Authorization': `token ${this.$store.state.token}`, // Get token from Vuex
        'Content-Type': 'application/json',
      };

      console.log("HERE", this.productModel)

      const specificationUpdateData = this.productModel.specifications.map((spec) => {
        const modifiedOptions = spec.options.map((it) => {
          if (it.type === "PRICE") {
            return {
              ...it,
              price: Number(it.price)
            };
          } else if (it.type === "DELIVERY_FEE") {
            return {
              ...it,
              price: Number(it.price),
              fromQuantity: Number(it.fromQuantity),
              toQuantity: Number(it.toQuantity)
            };
          }
          return it;
        })

        return {
          ...spec,
          options: modifiedOptions
        }
      });

      console.log("specificationUpdateData", specificationUpdateData)

      const updateData = {
        ...this.productModel,  // Spread the existing product model to copy all properties
        specifications: specificationUpdateData  // Overwrite specifications with the new array
      };

      console.log("updateData", updateData)

      try {
        const response = await axios.patch(url, updateData, { headers });
        if(response.data.statusCode == 200) {
          alert("Product updated!")
          this.$router.push('/products');
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        console.error('Error updating product:', error);
        alert("Error!")
      }
    },
    async createProduct() {
      const url = `${this.$store.state.baseUrl}/product`; // Get baseUrl from Vuex
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Authorization': `token ${this.$store.state.token}`, // Get token from Vuex
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.post(url, this.productModel, { headers });
        console.log('Product created:', response.data);
        alert("Product created!")
        this.$router.push('/products');
      } catch (error) {
        console.error('Error creating product:', error);
        alert("Error!")
      }
    },
    async getProduct() {
      const url = `${this.$store.state.baseUrl}/product/${this.productId}`; // Get baseUrl from Vuex
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Authorization': `token ${this.$store.state.token}`, // Get token from Vuex
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(url, { headers });
        console.log('Product fetched:', response.data);
        const res = response.data.data
        this.productModel = {
          ...res,
          specifications: this.sortSpecifications(res.specifications)
        }
        // console.log(this.productModel)
      } catch (error) {
        console.error('Error fetching product:', error);
        alert("Error!")
      }
    },
    async getCategories() {
      const url = `${this.$store.state.baseUrl}/category`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Authorization': `token ${this.$store.state.token}`, 
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(url, { headers });
        console.log('categories fetched:', response.data.data);
        const res = response.data.data
        this.categories = res
      } catch (error) {
        console.error('Error fetching categories:', error);
        alert("Error!")
      }
    },
    async onFileSelected(event) {
      const file = event.target.files[0]
      if (file != null) {
        console.log(file)
        this.selectedFile = file;
        const newPath = await this.uploadFile()

        // Wait for 3 seconds before continuing
        await new Promise(resolve => setTimeout(resolve, 3000));
        
        this.productModel.images.push(newPath)
        this.selectedFile = null
        this.refreshImage++;
      } else {
          alert('No file selected')
      }
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      const url = `${this.$store.state.baseUrl}/utils/file-upload`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Content-Type': 'multipart/form-data',
        'Authorization': `token ${this.$store.state.token}`
      };

      try {
        const response = await axios.post(url, formData, { headers });
        console.log('File upload response:', response.data);
        if (response.data.statusCode === 200) {
          return response.data.data.path
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        alert('Error uploading file:', error);
      }
    }
  },
  created() {
    if (this.productId != 0) {
      this.getProduct()
    }
    this.getCategories()
  },
}
</script>

<style>
.text-red {
  color: red;
}

.spec-option {
  border: 1px solid grey;
  border-radius: 16px;
  padding: 5px 10px;
  margin-right: 10px;
}

.dotted {
  border: none;
  border-top: 2px dotted #000;
  color: #fff;
  /* for IE */
  background-color: #fff;
  /* for IE */
  height: 1px;
}
</style>


<!-- <div class="spec-option d-flex">
  <p>Doff</p>
  <p class="ml-8 text-red">x</p>
</div> -->
