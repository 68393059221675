<template>
  <div class="about">
    <h1>Blog Detail</h1>
    <v-container>
      <v-card>
        <v-container class="d-flex flex-column text-left">
          <v-row>
            <v-col cols="2">
              <p><b>Title</b></p>
            </v-col>
            <v-col>
              <v-text-field
                density="compact"
                hide-details
                required
                value="Rekomendasi Bahan Banner"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Content</b></p>
            </v-col>
            <v-col>
              <v-textarea
                density="compact"
                hide-details
                required
                value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p><b>Images</b></p>
            </v-col>
            <v-col>
              <v-container class="d-flex flex-column">
                <v-row>
                  <v-col cols="2">
                    <v-img
                      class="mx-auto"
                      height="100"
                      lazy-src="https://picsum.photos/id/11/100/60"
                      max-width="200"
                      src="https://bad.src/not/valid"
                    >
                      <template v-slot:placeholder>
                        <div
                          class="d-flex align-center justify-center fill-height"
                        >
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                    <v-btn size="small" outlined>Delete</v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-img
                      class="mx-auto"
                      height="100"
                      lazy-src="https://picsum.photos/id/11/100/60"
                      max-width="200"
                      src="https://bad.src/not/valid"
                    >
                      <template v-slot:placeholder>
                        <div
                          class="d-flex align-center justify-center fill-height"
                        >
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                    <v-btn size="small" outlined>Delete</v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-img
                      class="mx-auto"
                      height="100"
                      lazy-src="https://picsum.photos/id/11/100/60"
                      max-width="200"
                      src="https://bad.src/not/valid"
                    >
                      <template v-slot:placeholder>
                        <div
                          class="d-flex align-center justify-center fill-height"
                        >
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                    <v-btn size="small" outlined>Delete</v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-img
                      class="mx-auto"
                      height="100"
                      lazy-src="https://picsum.photos/id/11/100/60"
                      max-width="200"
                      src="https://bad.src/not/valid"
                    >
                      <template v-slot:placeholder>
                        <div
                          class="d-flex align-center justify-center fill-height"
                        >
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                    <v-btn size="small" outlined>Delete</v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-img
                      class="mx-auto"
                      height="100"
                      lazy-src="https://picsum.photos/id/11/100/60"
                      max-width="200"
                      src="https://bad.src/not/valid"
                    >
                      <template v-slot:placeholder>
                        <div
                          class="d-flex align-center justify-center fill-height"
                        >
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                    <v-btn size="small" outlined>Delete</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <br />
                  <!-- upload start  -->
                  <v-container class="d-flex flex-column">
                    <p><b>Upload Image</b></p>
                    <div class="d-flex">
                      <v-file-input
                        label="File input"
                        density="compact"
                        class="mt-4 mr-4"
                      ></v-file-input>
                      <v-btn class="mt-4">Upload</v-btn>
                    </div>
                  </v-container>
                  <!-- upload end -->
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <br />
          <v-btn>Save Blog</v-btn>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>
