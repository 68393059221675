<template>
    <div class="about">
      <h1>Category List</h1>
      <v-container>
        <v-row>
            <v-col cols="2">
              <p><b>New Category</b></p>
            </v-col>
            <v-col>
              <v-text-field density="compact" hide-details required v-model="newCategory"></v-text-field>
            </v-col>
            <v-col>
                <v-btn class="mr-2" @click="createCategory">Submit</v-btn>
            </v-col>
          </v-row>
      </v-container>
      <v-container>
        <v-data-table :headers="headers" :items="checkoutList" :search="search">
            <template v-slot:[`item.action`]="{ item }">
          <v-btn class="mr-2" @click="deleteCategory(item.id)">Delete</v-btn>
        </template>
          <!-- <template v-slot:top>
            <v-text-field v-model="search" class="pa-2" label="Search"></v-text-field>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getBadgeColor(item.status)" small>
              {{ translateStatus(item.status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.userId`]="{ item }">
            <v-btn class="mr-2" :to="`/users/${item.userId}`">User</v-btn>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mr-2" :to="`/orders/${item.id}`">Detail</v-btn>
          </template> -->
        </v-data-table>
      </v-container>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        checkoutList: [],
        newCategory: "",
        headers: [
          { title: 'Created', value: 'created', sortable: true },
          { title: 'Name', value: 'name', sortable: true },
          { title: 'Action', value: 'action', sortable: false },
        ],
        search: ""
      };
    },
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      },
      async createCategory() {
        try {
          const response = await axios.post(`${this.$store.state.baseUrl}/category`, 
          {
            name: this.newCategory
          },
          {
            headers: {
              Authorization: `token ${this.$store.state.token}`,
            },
          });
          if(response.data.statusCode == 201) {
            alert("Category created")
            this.newCategory = ""
            this.fetchCategories()
          } else {
            alert(response.data.message)
          }
        } catch (error) {
          console.error('Error fetching categories:', error);
          alert("Error!");
        }
      },
      async deleteCategory(categoryId) {
        try {
          const response = await axios.delete(`${this.$store.state.baseUrl}/category/${categoryId}`, {
            headers: {
              Authorization: `token ${this.$store.state.token}`,
            },
          });
          if(response.data.statusCode == 200) {
            alert("Success delete category")
                        this.fetchCategories()
          } else {
            alert(response.data.message)
          }
        } catch (error) {
          console.error('Error fetching categories:', error);
          alert("Error!");
        }
      },
      async fetchCategories() {
        try {
          const response = await axios.get(`${this.$store.state.baseUrl}/category`, {
            headers: {
              Authorization: `token ${this.$store.state.token}`,
            },
          });
          this.checkoutList = response.data.data.map(
            (it) => ({
              ...it,
              created: this.formatDate(it.created),
              lastUpdated: this.formatDate(it.lastUpdated),
            })
          ).sort((a, b) => {
            return new Date(b.created) - new Date(a.created);
          });
        } catch (error) {
          console.error('Error fetching categories:', error);
          alert("Error!");
        }
      },
    },
    created() {
      this.fetchCategories()
    }
  };
  </script>
  