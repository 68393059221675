<template>
  <div class="about">
    <h1>Order List</h1>
    <v-container>
      <v-data-table :headers="headers" :items="checkoutList" :search="search">
        <template v-slot:top>
          <v-text-field v-model="search" class="pa-2" label="Search"></v-text-field>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getBadgeColor(item.status)" small>
            {{ translateStatus(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.userId`]="{ item }">
          <v-btn class="mr-2" :to="`/users/${item.userId}`">User</v-btn>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn class="mr-2" :to="`/orders/${item.id}`">Detail</v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      checkoutList: [],
      headers: [
        { title: 'Created', value: 'created', sortable: true },
        { title: 'Last Updated', value: 'lastUpdated', sortable: true },
        { title: 'No Referensi', value: 'refNumber', sortable: true },
        { title: 'Status', value: 'status', sortable: true },
        { title: 'User Name', value: 'user.name', sortable: true },
        { title: 'User', value: 'userId', sortable: false },
        { title: 'Action', value: 'action', sortable: false },
      ],
      search: ""
    };
  },
  methods: {
    getBadgeColor(status) {
      const colorMap = {
        'WAITING_PAYMENT_PROOF': 'blue',
        'WAITING_VERIFICATION': 'red',
        'PAYMENT_PROOF_REJECTED': 'grey',
        'PAYMENT_PROOF_APPROVED': 'grey',
        'COMPLETED': 'grey'
      };
      return colorMap[status] || 'grey'; // Default color if status is not found
    },
    translateStatus(status) {
      const statusMap = {
        'WAITING_PAYMENT_PROOF': 'Menunggu Pembayaran',
        'WAITING_VERIFICATION': 'Pembayaran Sedang Diverifikasi',
        'PAYMENT_PROOF_REJECTED': 'Pesanan Dibatalkan',
        'PAYMENT_PROOF_APPROVED': 'Pesanan Diproses',
        'COMPLETED': 'Pesanan Dikirim'
      };
      return statusMap[status] || status; // Return original status if not found in map
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    },
    async fetchCheckouts() {
      try {
        const response = await axios.get(`${this.$store.state.baseUrl}/checkout`, {
          headers: {
            Authorization: `token ${this.$store.state.token}`,
          },
        });
        this.checkoutList = response.data.data.map(
          (it) => ({
            ...it,
            created: this.formatDate(it.created),
            lastUpdated: this.formatDate(it.lastUpdated),
          })
        ).sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        });
        this.$store.dispatch('setCheckoutList', this.checkoutList)
      } catch (error) {
        console.error('Error fetching checkout:', error);
        alert("Error!");
      }
    },
  },
  created() {
    this.fetchCheckouts()
  }
};
</script>
