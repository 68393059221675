<template>
  <div class="about">
    <h1>Product List</h1>
    <v-container>
      <v-container>
        <router-link to="/products/create">
          <v-btn>Create New Product</v-btn>
        </router-link>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="2">
            <p><b>Category</b></p>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="8">
                <v-select
                  :items="categories"
                  label="Category"
                  outlined
                  v-model="filterCategory"
                  item-title="name"
                  item-value="id"
                  placeholder="Select Category"
                ></v-select>
              </v-col>
              <v-col>
                <v-btn @click="filterProducts">Filter</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table :headers="headers" :items="filteredProducts" :search="search">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            class="pa-2"
            label="Search"
          ></v-text-field>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn class="mr-2" :to="`/products/${item.id}`">Detail</v-btn>
          <v-btn @click="toggleFeatured(item)">Toggle Featured</v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      filterCategory: "",
      categories: [],
      products: [],
      filteredProducts: [],
      search: '',
      headers: [
        { title: 'Created', value: 'created', sortable: true },
        { title: 'Last Updated', value: 'lastUpdated', sortable: true },
        { title: 'Name', value: 'name', sortable: true },
        { title: 'Description', value: 'description', sortable: true },
        { title: 'Is Featured', value: 'isFeatured', sortable: true },
        { title: 'Category', value: 'categoryName', sortable: true },
        { title: 'Actions', value: 'action', sortable: false }
      ],
    };
  },
  methods: {
    async getCategories() {
      const url = `${this.$store.state.baseUrl}/category`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Authorization': `token ${this.$store.state.token}`, 
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(url, { headers });
        console.log('categories fetched:', response.data.data);
        this.categories = response.data.data;
      } catch (error) {
        console.error('Error fetching categories:', error);
        alert("Error!");
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    },
    async fetchProducts() {
      try {
        const response = await axios.get(`${this.$store.state.baseUrl}/product`, {
          headers: {
            Authorization: `token ${this.$store.state.token}`,
          },
        });
        this.products = response.data.data.map(
          (it) => ({
            ...it,
            created: this.formatDate(it.created),
            lastUpdated: this.formatDate(it.lastUpdated),
          })
        ).sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        });
        this.filterProducts(); // Apply initial filter
        console.log('Products fetched:', this.products);
      } catch (error) {
        console.error('Error fetching products:', error);
        alert("Error!");
      }
    },
    filterProducts() {
      if (this.filterCategory) {
        this.filteredProducts = this.products.filter(
          (product) => product.categoryId === this.filterCategory
        );
      } else {
        this.filteredProducts = this.products;
      }
    },
    async toggleFeatured(product) {
      const url = `${this.$store.state.baseUrl}/product/${product.id}`;
      const headers = {
        'Api-key': 'e556d445-ca4a-4b00-84a9-d553d954abf7',
        'Authorization': `token ${this.$store.state.token}`,
        'Content-Type': 'application/json',
      };
      product.isFeatured = !product.isFeatured;

      try {
        const response = await axios.patch(url, product, { headers });
        console.log('Product updated:', response.data);
        alert("Product updated!");
        this.fetchProducts(); // Refresh product list
      } catch (error) {
        console.error('Error updating product:', error);
        alert("Error!");
      }
    },
  },
  created() {
    this.fetchProducts(); 
    this.getCategories();
  },
};
</script>

<style scoped>
.pa-2 {
  padding: 16px;
}
</style>
