<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark flat>
                        <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field v-model="loginForm.email" :rules="emailRules" label="Email"
                                name="email" prepend-icon="mdi-account" type="text" required></v-text-field>

                            <v-text-field v-model="loginForm.password" :rules="passwordRules" label="Password"
                                name="password" prepend-icon="mdi-lock" type="password" required></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="login">Login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name: "LoginView",
    data() {
        return {
            loginForm: {
                email: '',
                password: ''
            },
            emailRules: [
                v => !!v || 'Email is required',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
            ]
        };
    },
    methods: {
        async login() {
            try {
                const response = await axios.post(`${this.$store.state.baseUrl}/auth/login`, 
                this.loginForm,
                {
                    headers: {
                        Authorization: `token ${this.$store.state.token}`,
                    },
                });
                if (response.data.statusCode == 200) {
                    const token = response.data.data.token
                    this.$store.dispatch('setToken', token)
                    console.log('Login token:', token);
                    this.$router.push('/');
                } else if(response.data.statusCode == 400) {
                    alert("Wrong email or password!")
                }
            } catch (error) {
                console.error('Error login:', error);
                alert("Error!");
            }
        },
    }
};
</script>

<style>
.fill-height {
    height: 100vh;
}
</style>