// store/index.js
// import Vue from 'vue';
import Vuex from 'vuex';

// Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "", // Example token (replace with your token)
    baseUrl: "https://api.cetaklabel.id",
    checkoutList: []
  },
  mutations: {
    setToken(state, token) {
      state.token = token; 
    },
    clearToken(state) {
      state.token = null; 
    },
    setCheckoutList(state, checkoutList) {
      state.checkoutList = checkoutList
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token); 
    },
    clearToken({ commit }) {
      commit('clearToken');
    },
    setCheckoutList({ commit }, checkoutList) {
      commit('setCheckoutList', checkoutList); 
    },
  },
  getters: {
    getToken: (state) => state.token,
    getBaseUrl: (state) => state.baseUrl,
    getCheckoutList: (state) => state.checkoutList
  },
});
