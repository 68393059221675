<template>
  <div class="about">
    <h1>Blog List</h1>
    <!-- <v-container>
      <v-container>
        <router-link to="/products/1"
          ><v-btn>Create New Blog</v-btn></router-link
        >
      </v-container>
      <v-data-table :items="items">
        <template v-slot:[`item.action`]="{}">
          <p>
            <router-link to="/blogs/1"><v-btn> Detail </v-btn></router-link>
          </p>
        </template>
      </v-data-table>
    </v-container> -->
    <v-container>
      Coming Soon
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Rekomendasi Bahan Spanduk Outdoor",
          createdOn: "2024-01-30",
          action: "",
        },
      ],
    };
  },
};
</script>
